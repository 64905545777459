$baseURL: 'https://blog.asitavsen.com/';
$fontsPath: '../fonts/';
$imagesPath: '../images/';
$iconsPath: '../icons/';
@import 'variables';
@import 'override';
@import 'fonts';
@import 'base';
@import 'components';
@import 'utils';
@import 'mobile';
@import 'syntax';
@import 'custom';
